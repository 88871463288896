import * as React from "react";
// import { ArticleCard } from './ArticleCard';
// import styles from './styles/Garden.module.scss';
import Blog from "../Medium/Blog";

// import articles from '../../articles/articles.json'

export function Garden(props) {
  return (
    <main>
      <div>
        <Blog />
      </div>
    </main>
  );
}

export default Garden;
